<template>
  <div class="mx-6 p-6 text-white bg-white/5 border border-white/5 rounded">
    <GiftIcon class="w-4 h-4" />
    <p class="mt-1 text-sm">
      {{ $t("gifts.looking_for_sth_custom") }}
    </p>
    <ActionLink
      is-external-link
      :href="customizationAdCTALink"
      class="block mt-1 text-xs"
      target="_blank"
      @click="trackCustomizationAdClick"
    >
      {{ $t("gifts.customization_cta") }}
    </ActionLink>
  </div>
</template>

<script setup>
import captureUserEvent from "@/helpers/captureUserEvent"
import ActionLink from "@/components/elements/links/ActionLink.vue"
import { GiftIcon } from "@heroicons/vue/20/solid"
defineProps({
  customizationAdCTALink: String,
})
const trackCustomizationAdClick = () => {
  captureUserEvent("General - Request custom clicked", {
    banner_type: "menu",
  })
}
</script>
